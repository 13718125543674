<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <br />
  <!-- FILTRAR USUÁRIOS-->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Filtrar conexões</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="endereco_estado">Página</label>

            <select class="custom-select rounded-0" v-model="user_selecionado" @change="searchContainers()">
              <option value=""></option>
              <option v-for="pagina in paginas" :key="pagina" :value="pagina">
                {{ pagina }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary" @click="searchContainers">Buscar</button>
        </div>
      </div>
    </div>
  </div>

  <admin-create-container
    v-if="criar_container"
    :users="users"
    :user_selecionado="user_selecionado"
    :setCriarContainer="setCriarContainer"
    :containerCriado="containerCriado"
  />

  <button
    data-toggle="modal"
    data-target="#create-container-modal"
    @click="selecionarContainer(container), (criar_container = true)"
    type="button"
    class="btn btn-success"
  >
    Nova conexão
    <i style="margin-left: 5px" class="fa fa-plus"></i>
  </button>
  <!-- TABELA USUÁRIOS-->
  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title">
        Conexões
        <span class="badge badge-warning" style="margin-left: 5px">{{ containers.length }}</span>
      </h3>
    </div>
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Whatsapp</th>
            <th>E-mail</th>
            <th>Cnpj</th>
            <th>Adicionais</th>
            <th>stage_changed_date</th>
            <th>Criado em</th>
            <th>Atualizado em</th>
            <th>Criado recentemente?</th>
            <th>Estágio</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lead in dados_leads" :key="lead">
            <td>{{ lead.id }}</td>
            <td>{{ lead.cf_whatsapp }}</td>
            <td>{{ lead.display_name }}</td>
            <td>{{ lead.cf_email }}</td>
            <td>{{ lead.cf_possuicnpj }}</td>
            <td>{{ lead.cf_adicionais }}</td>
            <td>
              <span>
                {{ formatDate(lead.stage_changed_date) }}
              </span>
            </td>
            <td>
              <span>
                {{ formatDate(lead.created_at) }}
              </span>
            </td>
            <td>
              <span>
                {{ formatDate(lead.updated_at) }}
              </span>
            </td>
            <td>{{ lead.was_recently_created }}</td>

            <td>
              <span v-if="lead.stage">
                {{ lead.stage.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import wppController from "./controller/wppController";
import sendController from "./controller/sendController";
import sweetAlert from "./controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["users"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      container_selecionado: "",
      containers: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_container: false,
      criar_container: false,
      user_selecionado: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      tag: "",
      cor_tag: "#FF0000",
      array_tags: [],
      array_historico: [],
      data_tag: "",
      tag_padrao_option: "",
      ultima_atualizacao_status: "",
      numero_conectado: "",
      nome_container: "",
      array_tags_padrao: [],
      dados_leads: "",
    };
  },

  mounted() {
    for (let i = 0; i <= 30; i++) {
      this.paginas.push(i + 1);
    }

    let data = {
      Nome: "Teste 01",
      Whatsapp: "21518123412",
    };

    let config = {
      headers: {
        name: "application/json",
        Accept: "application/json",
        Authorization: "Bearer 25|s0c07WBdhkJjZ8A8Yxae8FFWbTWmUnFzhhpOAIpTc06a92b2",
      },
    };

    axios
      .get("https://casadenegocios.corretorcrm.com/api/deals/", data, config)
      .then((response) => {
        console.log("Resposta");
        console.log(response);
        if (response.data.data) {
          console.log("Data data");
          this.dados_leads = response.data;
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    buscarDados() {},

    calcularValor(created_at) {
      let dataReferencia = moment(new Date());
      let data = moment(created_at);

      var duration = moment.duration(dataReferencia.diff(data));
      var hours = duration.asHours();

      if (hours < 1) {
        var min = duration.asMinutes();
        return ~~min + " min.";
      } else if (hours >= 24) {
        var days = duration.asDays();

        let tempo = " dias";
        if (days < 2) {
          tempo = " dia";
        }

        return ~~days + tempo;
      } else {
        let tempo = " horas";
        if (hours < 2) {
          tempo = " hora";
        }

        return ~~hours + tempo;
      }
    },

    salvarInformacoes() {
      let data = {
        container_id: this.container_selecionado.id,
        historico: JSON.stringify(this.array_historico),
        tags: JSON.stringify(this.array_tags),
        ultima_atualizacao_status: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        comentarios: this.container_selecionado.comentarios,
      };

      if (this.user_selecionado) {
        data.user_id = this.user_selecionado.id;
      }

      console.log(data);

      axios
        .post(`/admin/containers/atualizar_informacoes`, data)
        .then((response) => {
          this.showSuccessMessageWithButton("Sucesso", "Informações atualizadas");

          let indice = this.find(this.container_selecionado.id);

          this.containers[indice].tags = JSON.stringify(this.array_tags);
          this.containers[indice].tags_formatadas = this.array_tags;
          this.containers[indice].historico = JSON.stringify(this.array_historico);
          this.containers[indice].comentarios = this.container_selecionado.comentarios;

          let btn = document.getElementById("close_modal_info");
          if (btn) {
            btn.click();
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    find(id) {
      let indice = this.containers.findIndex((element) => element.id == id);
      return indice;
    },

    exibirInformacoes(container) {
      this.container_selecionado = container;

      let arr = JSON.parse(this.container_selecionado.tags);

      if (!arr) {
        arr = [];
      }

      let arr_historico = JSON.parse(this.container_selecionado.historico);

      if (!arr_historico) {
        arr_historico = [];
      }

      this.array_tags = arr;
      this.array_historico = arr_historico;
    },

    deletarHistorico(index) {
      let arr_historico = JSON.parse(this.container_selecionado.historico);

      if (arr_historico) {
        arr_historico.splice(index, 1);

        this.container_selecionado.historico = JSON.stringify(arr_historico);
        this.array_historico = arr_historico;
      }
    },

    addHistorico(tag) {
      console.log("Historico");

      this.array_historico.push(tag);
      this.array_historico = this.array_historico.sort(this.compare);

      this.container_selecionado.historico = JSON.stringify(this.array_historico);
    },

    removerTag(index) {
      let arr = JSON.parse(this.container_selecionado.tags);

      if (arr) {
        arr.splice(index, 1);

        this.container_selecionado.tags = JSON.stringify(arr);
        this.array_tags = arr;
      }
    },

    compare(a, b) {
      let data1 = new Date(a.data);
      let data2 = new Date(b.data);

      console.log("Comparando");

      if (data1 > data2) {
        return -1;
      }
      if (data1 < data2) {
        return 1;
      }
      return 0;
    },

    addTag() {
      if (this.tag) {
        let arr = JSON.parse(this.container_selecionado.tags);

        if (!arr) {
          arr = [];
        }

        let tag = { tag: this.tag, cor: this.cor_tag, data: moment(this.data_tag).format("yyyy-MM-DD HH:mm:ss") };
        arr.push(tag);
        this.addHistorico(tag);
        this.ultima_atualizacao_status = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");

        this.array_tags = arr;
        this.tag = "";

        this.container_selecionado.tags = JSON.stringify(arr);
      }
    },

    addTagPadrao() {
      if (this.tag_padrao_option) {
        console.log("Adicionando");
        console.log(this.tag_padrao_option);

        let arr = JSON.parse(this.container_selecionado.tags);

        if (!arr) {
          arr = [];
        }

        if (typeof this.tag_padrao_option == "string") {
          this.tag_padrao_option = JSON.parse(this.tag_padrao_option);
        }

        this.tag_padrao_option.data = moment(this.data_tag).format("yyyy-MM-DD HH:mm:ss");

        arr.push(this.tag_padrao_option);
        this.addHistorico(this.tag_padrao_option);
        this.ultima_atualizacao_status = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");

        this.array_tags = arr;
        this.tag_padrao_option = "";

        this.container_selecionado.tags = JSON.stringify(arr);
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    enviarTeste() {
      console.log(this.container_selecionado);

      if (this.telefone_teste) {
        this.telefone_teste = this.telefone_teste.replace(/[^Z0-9]/g, "");

        //ADICIONANDO 55 CASO NÃO TENHA
        if (this.telefone_teste.substr(0, 2) != "55") {
          this.telefone_teste = "55" + this.telefone_teste;
        }

        this.mensagem_teste_envio = "Enviando agora...";

        let porta = this.container_selecionado.porta;

        if (!porta) {
          porta = this.container_selecionado.porta_usuario;
        }
        this.validarNumero(this.telefone_teste, this.container_selecionado.chave_api, porta, (validNumber, timeout) => {
          if (validNumber) {
            this.enviar("texto", validNumber, "Teste", "", this.container_selecionado.chave_api, porta, (timeout, phone, sucess) => {
              if (timeout) {
                this.mensagem_teste_envio = "Verifique a aba Conexão...";
              } else {
                //Verificando se foi enviado com sucesso
                console.log("Checking status...");

                if (sucess) {
                  console.log("Success");
                  //Prosseguir envio
                  if (phone) {
                    this.mensagem_teste_envio = "Mensagem enviada...";
                  } else {
                    this.mensagem_teste_envio = "Ops... Falha no envio";
                  }
                } else {
                  this.mensagem_teste_envio = "Falha no envio";
                }
              }
            });
          } else {
            if (timeout) {
              this.mensagem_teste_envio = "Falha ao validar número, conexão instável...";
            } else {
              this.mensagem_teste_envio = "WhatsApp inválido...";
            }
          }
        });
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchContainers();
    },

    containerCriado() {
      this.setCriarContainer(false);

      this.searchContainers();
    },

    setCriarContainer(value) {
      let btn = document.getElementById("close_create_container");
      if (btn) {
        btn.click();
      }

      this.criar_container = value;
    },

    containerEditado() {
      this.setEditarContainer(false);

      this.searchContainers();
    },

    setEditarContainer(value) {
      let btn = document.getElementById("close_edit_container");
      if (btn) {
        btn.click();
      }

      this.editar_container = value;
    },

    searchContainers() {
      let data = {
        filtro: this.filtro,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
        numero_conectado: this.numero_conectado,
        nome_container: this.nome_container,
      };

      if (this.user_selecionado) {
        data.user_id = this.user_selecionado.id;
      }

      axios
        .post(`/admin/connections/search`, data)
        .then((response) => {
          this.containers = response.data.containers;
          this.total = response.data.total;

          this.pagination(response.data);

          for (let i = 0; i < this.containers.length; i++) {
            this.containers[i].tags_formatadas = JSON.parse(this.containers[i].tags);
          }

          console.log("containers");
          console.log(this.containers);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchContainers();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarContainer(usuario) {
      this.container_selecionado = usuario;
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(container) {
      let html =
        "Deseja <b style='color: red'> deletar</b> a conexão " +
        "<b style='font-size:25px'>" +
        container.nome +
        "</b><br/> do usuário <b>" +
        container.usuario +
        "</b>?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(container);
          }
        });
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    deletar(container) {
      let data = {
        id: container.id,
      };

      axios
        .post(`/user/connection/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Conexão deletada");

          this.searchContainers();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        id: this.container_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/containers/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.modal-content {
  width: 1100px;
  margin-left: -250px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -20px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .modal-content {
    width: 600px;
    margin-left: -20px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 960px) {
  .modal-content {
    width: 750px;
    margin-left: -120px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
